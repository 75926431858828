<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                    </div>
                    <table class="table table-responsive">
                        <tbody>
                            <tr>
                                <th>{{ tt('type') }}</th>
                                <td>Material Revision</td>
                                <th>{{ tt('created_by') }}</th>
                                <td>Administrator SmartCat</td>
                            </tr>
                            <tr>
                                <th>{{ tt('mcr_number') }}</th>
                                <td>-001/2020</td>
                                <th>{{ tt('created_at') }}</th>
                                <td>2020-06-09 18:09:12</td>
                            </tr>
                            <tr>
                                <th>{{ tt('subject') }}</th>
                                <td>REVISI MATERIAL</td>
                                <th>{{ tt('day') }}</th>
                                <td>66 Day</td>
                            </tr>
                            <tr>
                                <th>{{ tt('catalog_type') }}</th>
                                <td>SWH</td>
                                <th>{{ tt('revision') }}</th>
                                <td>1</td>
                            </tr>
                            <tr>
                                <th>{{ tt('status') }}</th>
                                <td>MCR REJECTED BY MANAGER CATALOGER</td>
                            </tr>
                        </tbody>
                    </table>    
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>MCR Item</h3>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="tracking">{{ tt('tracking') }}</base-button>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.item">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.no }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.description }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('material_type')" :prop="tt('material_type')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_type }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('material_group')" :prop="tt('material_group')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_group }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('uom')" :prop="tt('uom')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.uom }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <modal :show.sync="formTracking.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
            </template>
            <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking">
                <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.date }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.status }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.approver }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('jabatan')" :prop="tt('jabatan')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.jabatan }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.message }}
                    </template>
                </el-table-column>
            </el-table>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                formTracking: {
                    show: false
                },      
                table: {
                    data: [
                        {
                            id: 1,
                            no: 1,
                            type: "Material Revision",
                            mcr_number: "-001/2020",
                            subject: "REVISI MATERIAL",
                            revision: "1",
                            created_by: "Administrator",
                            created_at: "2020-06-09 18:09:12",
                            day: "66 Day",
                            total_item: "1 Item",
                            workflow_status: "MCR REJECTED BY MANAGER CATALOGER",
                        },
                        {
                            id: 2,
                            no: 2,
                            type: "Material Revision",
                            mcr_number: "-001/2020",
                            subject: "REVISI MATERIAL",
                            revision: "1",
                            created_by: "Administrator",
                            created_at: "2020-06-09 18:09:12",
                            day: "66 Day",
                            total_item: "1 Item",
                            workflow_status: "MCR REJECTED BY MANAGER CATALOGER",
                        },
                    ],
                    item: [
                        {
                            id: 1,
                            no: 1,
                            material_number: 100001,
                            description: 'test',
                            material_type: 1010,
                            material_group: 1001,
                            uom: '%'
                        },
                        {
                            id: 2,
                            no: 2,
                            material_number: 100001,
                            description: 'test',
                            material_type: 1010,
                            material_group: 1001,
                            uom: '%'
                        },
                    ],
                    tracking: [
                        {
                            id: 1,
                            date: '2020-06-12 17:47:53',
                            status: 'MCR APPROVAL SENT TO CATALOGER',
                            approver: 'Administrator SmartCat',
                            jabatan: 'Senior Cataloger',
                            message: 'test'
                        }
                    ]
                },        
                equipmentCode: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            tracking() {
                this.formTracking.title = this.tt('add_equipment_code');
                this.formTracking.show = true;
            },
        }   
    };
</script>
<style></style>
